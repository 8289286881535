<template>
  <ckeditor ref="ckedi" v-bind="$attrs" :editor="editor" :config="editorConfig"
            v-on="$listeners"
  />
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'
import Font from '@ckeditor/ckeditor5-font/src/font'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import List from '@ckeditor/ckeditor5-list/src/list'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'

export default {
  name: 'RichText',
  inheritAttrs: false,
  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          Underline,
          Strikethrough,
          LinkPlugin,
          List,
          Alignment,
          Heading,
          ParagraphPlugin,
          Font,
          FontFamily,
          Highlight,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          LinkImage,
          ImageInsert,
          AutoImage,
          Base64UploadAdapter,
          MediaEmbed
        ],
        fontSize: {
          options: [
            9,
            11,
            13,
            'default',
            17,
            19,
            21,
            24
          ]
        },
        toolbar: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'alignment:left',
          'alignment:center',
          'alignment:right',
          'alignment:justify',
          'bulletedList',
          'numberedList',
          'fontColor',
          'highlight',
          'fontFamily',
          'fontSize',
          'linkImage',
          'insertImage',
          'mediaEmbed'
        ],
        image: {
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative'
          ]
        },
        fontFamily: {
          supportAllValues: true
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
