<template>
  <v-btn
    v-bind="$attrs"
    class="base-button"
    height="55"
    rounded
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false
}
</script>

<style scoped lang="scss">
.base-button {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  color: #FFFFFF;
  text-transform: none;
  letter-spacing: 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

</style>
